export const ASSET_DISABLED_REASON = {
  generic: 'generic',
  listed: 'listed',
  primarySale: 'primarySale',
  snapshot: 'snapshot',
  transferring: 'transferring',
  locked: 'locked'
} as const

export const ASSET_DISABLED_MESSAGE = {
  [ASSET_DISABLED_REASON.listed]:
    'Assets that are listed for sale can not be transferred. To cancel a listing, go to the Listings tab in your Inventory.',
  [ASSET_DISABLED_REASON.generic]:
    'Transfers for this drop are currently disabled. Please try again later.',
  [ASSET_DISABLED_REASON.primarySale]:
    'You must wait until the drop sale concludes before you can list items.',
  [ASSET_DISABLED_REASON.snapshot]:
    'This drops’ snapshot is currently taking place. Please wait until the snapshot is complete to be able to transfer this item.',
  [ASSET_DISABLED_REASON.transferring]:
    'This item is in the process of transferring to your account on the blockchain. This process can take up to two minutes.',
  [ASSET_DISABLED_REASON.locked]: 'Waiting on blockchain.'
}
