import { PropsWithChildren } from 'react'
import ToggleFilterButton from '@/components/ToggleFilterButton'
import classnames from 'classnames'
import useDetailPresentationStyle from './hooks/useDetailPresentationStyle'

interface Props extends PropsWithChildren {
  className?: string
  collapsed: boolean
  isExpanded: boolean
  onToggle: () => void
}

export default function InventoryBrowserFilterWrapper({
  children,
  className,
  isExpanded,
  collapsed,
  onToggle
}: Props) {
  const presentationStyle = useDetailPresentationStyle()
  const isModal = presentationStyle === 'modal'

  const childrenClassNames = isModal
    ? ''
    : 'bg-black absolute inset-0 overflow-y-scroll z-10'

  const wrapperClassNames = isModal
    ? ''
    : "before:content-[''] before:absolute before:inset-0 before:border-r before:border-defaultBorder before:z-[31] before:pointer-events-none"

  return (
    <div
      className={classnames(
        'sticky top-[var(--headerHeight)] flex-1 w-full max-md:-mt-3',
        {
          ['max-h-[calc(100vh-var(--headerHeight))]']: !isModal
        },
        className,
        wrapperClassNames,
        { '!w-[76px]': !isModal && collapsed }
      )}
    >
      <div className={classnames(childrenClassNames, 'overflow-x-hidden')}>
        {children}
      </div>
      {!isModal && (
        <ToggleFilterButton onClick={onToggle} isExpanded={isExpanded} />
      )}
    </div>
  )
}
