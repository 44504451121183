import classNames from 'classnames'
import React, { CSSProperties, MouseEvent } from 'react'
import { Icon, Button } from '..'
import { FrozenAssetIndicator } from './FrozenAssetIndicator'
import {
  ASSET_DISABLED_REASON,
  ASSET_DISABLED_MESSAGE
} from '@/config/inventory'
import useBreakpoints from '@/hooks/useBreakpoints'

interface IInventoryItemSelector {
  className: string
  disabledReason?: keyof typeof ASSET_DISABLED_REASON
  style?: CSSProperties
  isSmallLayout?: boolean
  isHovered?: boolean
  selected: boolean
  activeSelector?: boolean
  onChange?: (e: MouseEvent) => void
}

const InventoryItemSelector = ({
  className,
  disabledReason,
  selected,
  activeSelector,
  style,
  isSmallLayout,
  isHovered,
  onChange
}: IInventoryItemSelector) => {
  const { isMobile } = useBreakpoints()

  const activeState = isMobile || isHovered || activeSelector

  const inventoryItemSelectorStyles: CSSProperties = {
    width: `${isSmallLayout ? 'max(24px, 3.5em)' : 'max(24px, 4em)'}`,
    height: `${isSmallLayout ? 'max(24px, 3.5em)' : 'max(24px, 4em)'}`
  }

  if (disabledReason) {
    return (
      <FrozenAssetIndicator
        message={ASSET_DISABLED_MESSAGE[disabledReason]}
        style={{ ...style, ...inventoryItemSelectorStyles }}
        className={className}
      />
    )
  }

  return (
    <Button
      tabIndex={-1}
      className={classNames(
        className,
        'flex items-center justify-center w-[20px] h-[20px] bg-gray-900 rounded-full',
        'border-[1.5px] border-white border-opacity-15 bg-opacity-[0.08] transition-colors',
        {
          '!border-opacity-100': isHovered,
          'bg-opacity-[0.72] !border-opacity-100': activeState,
          '!bg-blue !border-none': selected
        }
      )}
      style={{
        ...inventoryItemSelectorStyles,
        ...style
      }}
      theme="clean"
      size="auto"
      onClick={onChange}
    >
      {selected && (
        <Icon name="checkMark" className="w-[48%] h-[48%] overflow-visible" />
      )}
    </Button>
  )
}

export default InventoryItemSelector
