import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { getDropBrandImage } from '@/config/drops'
import { useLayoutData } from '@/contexts/layout'
import { RARITY_TYPES } from '@/enum'
import CatalogItem from '@/components/ProductCard/Catalog'
import InventoryItem from '@/components/ProductCard/Inventory'
import InventoryItemActionable from '@/components/ProductCard/Inventory/InventoryItemActionable'
import { useInventory } from '@/hooks/useInventory'

const ACTIONABLE_ITEM_BUTTON_LABELS = {
  redeemable: {
    isListed: 'Can’t Redeem While Listed',
    isListedShort: 'Listed',
    default: 'Redeem'
  },
  openable: {
    isListed: 'Can’t Open While Listed',
    isListedShort: 'Listed',
    default: 'Open'
  }
}

export type BrowserGridItemType = {
  // @TODO any - after we merge RQ infinite I will work to fix this..may be because of RQ 4 - Josh Dobson - 4/28/23
  asset: any
  activeId: number
  isCatalog?: boolean
  isSmallLayout?: boolean
  isPublic: boolean
  isActive: boolean
}

export default function BrowserGridItem({
  asset,
  activeId,
  isCatalog = false,
  isSmallLayout,
  isPublic,
  isActive
}: BrowserGridItemType) {
  const router = useRouter()
  const [opening, setOpening] = useState(false)
  const { selectedAssets, updateSelectedAssets, openInventoryDetail } =
    useLayoutData()

  const { openAsset } = useInventory()

  const handleSelect = asset => {
    updateSelectedAssets(asset)
  }

  const handleRedeemAction = () => {
    router.push(`/redemptions/checkout/?drop_id=${asset.drop_id}`)
  }

  const handleOpenAssetAction = () => {
    setOpening(true)
    openAsset(asset.id, asset.open_url).finally(() => setOpening(false))
  }

  const handleItemClick = () => {
    openInventoryDetail(activeId)
  }

  const isListed = !!asset.listing_price

  const itemProps = {
    key: asset.id,
    isSmallLayout,
    asset,
    isActive,
    isCatalogAsset: isCatalog,
    className: 'card--hover',
    onClick: handleItemClick
  }

  const inventoryItemProps = {
    ...itemProps,
    isPublic,
    isActive,
    isItemSelectedForTransfer:
      selectedAssets &&
      selectedAssets.map(inventory => inventory.id).includes(asset.id),

    isAnyItemSelectedForTransfer: !!selectedAssets?.length,
    onItemTransferSelectionChange: () => handleSelect(asset)
  }

  if (router.query.show_only_listings || isCatalog) {
    const brandImage = getDropBrandImage(asset.drop_id)

    return (
      <CatalogItem
        isSmallLayout={isSmallLayout}
        drop_name={asset.drop_name}
        brandImage={brandImage}
        {...itemProps}
      />
    )
  }

  // Inventory Item - Redeemable
  if (
    asset.redeemable &&
    asset.rarity === RARITY_TYPES.Redeemable &&
    !isPublic
  ) {
    const primaryActionLabel = isListed
      ? isSmallLayout
        ? ACTIONABLE_ITEM_BUTTON_LABELS.redeemable.isListedShort
        : ACTIONABLE_ITEM_BUTTON_LABELS.redeemable.isListed
      : ACTIONABLE_ITEM_BUTTON_LABELS.redeemable.default
    return (
      <InventoryItemActionable
        isSmallLayout={isSmallLayout}
        disabled={isListed}
        primaryActionLabel={primaryActionLabel}
        onPrimaryAction={handleRedeemAction}
        {...inventoryItemProps}
      />
    )
  }

  // Inventory Item - openable
  if (asset.openable && !isPublic) {
    const primaryActionLabel = isListed
      ? isSmallLayout
        ? ACTIONABLE_ITEM_BUTTON_LABELS.openable.isListedShort
        : ACTIONABLE_ITEM_BUTTON_LABELS.openable.isListed
      : ACTIONABLE_ITEM_BUTTON_LABELS.openable.default
    return (
      <InventoryItemActionable
        isSmallLayout={isSmallLayout}
        disabled={isListed}
        loading={opening}
        key={asset.id}
        primaryActionLabel={primaryActionLabel}
        onPrimaryAction={handleOpenAssetAction}
        {...inventoryItemProps}
      />
    )
  }

  // Public/Private Inventory Item
  // // private items can be transferred if asset allows
  // // tapping item opens detail pane

  return <InventoryItem {...inventoryItemProps} />
}
