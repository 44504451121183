import classNames from 'classnames'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'
import { CSSTransition } from 'react-transition-group'
import Popup from 'reactjs-popup'
import { useLayoutData } from '@/contexts/layout'
import useBreakpoints from '@/hooks/useBreakpoints'
import { Icon } from '@/components'
import styles from './styles.module.scss'

interface IProps {
  defaultOpen: boolean
  title: string
  icon: string
  actionButton?: ReactNode
  collapse: boolean
  children?: ReactNode
}

const Section = ({
  defaultOpen,
  title,
  icon,
  actionButton,
  collapse,
  children
}: IProps) => {
  const [open, setOpen] = useState(defaultOpen)
  const [collapsed, setCollapsed] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [active, setActive] = useState(false)
  const { setSectionCollapseState } = useLayoutData()
  const { isMobile } = useBreakpoints()
  const rootRef = useRef<HTMLInputElement>()

  useEffect(() => {
    const el = rootRef.current

    const items = Array.from(el.parentNode.children)
    const index = items.indexOf(el)
    const total = items.length

    let timer1, timer2
    if (collapse) {
      timer1 = setTimeout(() => setCollapsed(true), 300 + index * 100)
      setExpanded(false)
    } else {
      timer1 = setTimeout(() => setCollapsed(false), (total - index - 1) * 100)
      timer2 = setTimeout(() => setExpanded(true), total * 100)
    }

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [collapse])

  const handleToggleOpen = () => {
    setOpen(!open)
    setSectionCollapseState(title, open)
  }

  return (
    <div ref={rootRef} className={styles.container}>
      <Popup
        className="inventory-filter-tooltip"
        trigger={() => (
          <div className={styles.triggerWrapper}>
            <CSSTransition in={collapsed} timeout={300} unmountOnExit>
              <div
                className={classNames(styles.trigger, {
                  [styles.active]: active
                })}
              >
                <Icon className={styles.headerIcon} name={icon} />
              </div>
            </CSSTransition>
          </div>
        )}
        closeOnDocumentClick
        keepTooltipInside
        position={['right center']}
        on={['hover', 'focus']}
        onOpen={() => setActive(true)}
        onClose={() => setActive(false)}
      >
        <div className={styles.tooltipHeader}>
          <Icon className={styles.headerIcon} name={icon} />
          {title}
          {actionButton}
        </div>
        {!!children && <div className={styles.tooltipContent}>{children}</div>}
      </Popup>

      <CSSTransition in={isMobile || expanded} timeout={300} unmountOnExit>
        <div
          className={classNames(
            styles.fadeAni,
            'border-t border-defaultBorder'
          )}
        >
          <div className={styles.header} onClick={handleToggleOpen}>
            <Icon className={styles.headerIcon} name={icon} />
            <div className={classNames(styles.title, 'h6')}>{title}</div>
            {actionButton}
            <Icon
              className={classNames(styles.expandIcon, {
                [styles.collapse]: open,
                hidden: !children
              })}
              name="arrow-down"
            />
          </div>
          {!!children && (
            <Collapse
              isOpened={open}
              theme={{ collapse: styles.collapse, content: styles.content }}
            >
              {children}
            </Collapse>
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

export default Section
