import React, { CSSProperties } from 'react'
import { Icon } from '..'
import Popup from '@/components/Popup'

interface Props {
  message: string
  style?: CSSProperties
  className?: string
}
export const FrozenAssetIndicator = ({ message, style, className }: Props) => {
  return (
    <div style={{ ...style }} className={className}>
      <Popup
        className="!p-2 !rounded-3xl"
        position="bottom left"
        size="md"
        trigger={
          <div className="bg-gray-900/[0.72] p-[2px] w-full h-full rounded-full">
            <div className="animate-spin w-full h-full border-solid border-[2px] border-white border-b-transparent rounded-[50%] inline-block box-border"></div>
          </div>
        }
      >
        <div className="flex gap-1">
          <Icon name="informationalFilled" className="w-2 h-2 flex-none" />
          <div className="body-sm">{message}</div>
        </div>
      </Popup>
    </div>
  )
}
