import InventoryItem, { InventoryItemInterface } from '.'
import Button from '@/components/Button'

interface InventoryItemActionableInterface extends InventoryItemInterface {
  onPrimaryAction: () => void
  isSmallLayout?: boolean
  disabled?: boolean
  loading?: boolean
  primaryActionLabel: string
}

const InventoryItemActionable = ({
  disabled,
  loading,
  isSmallLayout,
  onPrimaryAction,
  primaryActionLabel,
  ...props
}: InventoryItemActionableInterface) => {
  const customRoundedButtonClasses = isSmallLayout
    ? 'h-[4em] rounded-[1.5em] mt-[1.25em]'
    : 'h-[6em] rounded-[2em] mt-[2em]'

  return (
    <InventoryItem
      isSmallLayout={isSmallLayout}
      actionableButtonSlot={
        <Button
          className={`w-full relative z-20 px-2 ${customRoundedButtonClasses}`}
          theme="rainbow"
          size="auto"
          loading={loading}
          disabled={disabled}
          onClick={onPrimaryAction}
        >
          <span className={isSmallLayout ? 'text-[1.5em]' : 'text-[1.75em]'}>
            {primaryActionLabel}
          </span>
        </Button>
      }
      {...props}
    />
  )
}

export default InventoryItemActionable
