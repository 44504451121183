import classNames from 'classnames'
import { ReactNode, useState } from 'react'
import ProductCard from '..'
import { AssetAttributes } from '@/api/resources/shared/assetAttributes'
import { MediaItem } from '@/api/resources/shared/media'
import { Rarity } from '@/api/resources/shared/rarity'
import InventoryItemSelector from '@/components/InventoryItem/InventoryItemSelector'
import { ProductCardInfoRenderer } from '@/components/ProductCard/ProductCardInfo'
import { isTransferring } from '@/util/inventoryHelpers'
import { ASSET_DISABLED_REASON } from '@/config/inventory'
import ExclusiveProductBadge from '@/components/ProductCard/ExclusiveProductBadge'
import { BinaryEnum } from '@/util/zodHelpers'
import MarketplaceBadges from '@/components/ItemDetail/MarketplaceBadges'

type AssetLike = {
  collection_id?: number
  name?: string
  media?: MediaItem[]
  drop_name?: string
  attributes?: AssetAttributes
  mint_num?: number
  mint_count?: number
  trait_id?: number
  migrating?: BinaryEnum
  rarity?: Rarity
  redeemable?: boolean
  listing_price?: number
  transferable?: boolean
  drop_marketplace_disabled?: boolean
  drop_marketplace_disabled_snapshot?: boolean
  drop_marketplace_disabled_primary?: boolean
  exclusive?: boolean
  chain_status_text?: string
}

export interface AssetInterface {
  overlayChildren?: ReactNode
  infoFooterSlot?: ReactNode
  pillSlot?: ReactNode
  className?: string
  asset: AssetLike
  brandImage?: {
    id: string
    path: string
  }
  isCatalogAsset?: boolean
}

export interface InventoryItemInterface extends AssetInterface {
  mediaClasses?: string
  isPublic?: boolean
  disableTransfer?: boolean
  isActive?: boolean
  className?: string
  isSmallLayout?: boolean
  onClick?: () => void
  pillSlot?: ReactNode
  actionableButtonSlot?: ReactNode
  isItemSelectedForTransfer: boolean
  isAnyItemSelectedForTransfer: boolean
  onItemTransferSelectionChange: () => void
}

const InventoryItem = ({
  disableTransfer = false,
  mediaClasses,
  actionableButtonSlot,
  isAnyItemSelectedForTransfer,
  onItemTransferSelectionChange,
  isItemSelectedForTransfer,
  asset,
  isSmallLayout,
  isPublic,
  className,
  ...props
}: InventoryItemInterface) => {
  const [isHovered, setIsHovered] = useState(false)
  const {
    exclusive,
    transferable,
    listing_price,
    rarity,
    redeemable,
    migrating,
    attributes,
    drop_name,
    name,
    drop_marketplace_disabled,
    drop_marketplace_disabled_primary,
    drop_marketplace_disabled_snapshot,
    chain_status_text
  } = asset

  const assetIsListed = listing_price !== undefined && listing_price !== null
  const isTransferable =
    !isPublic && transferable && !disableTransfer && !assetIsListed
  const productInfoClasses = isSmallLayout ? 'p-[1.5em]' : 'p-[3em]'
  const shouldShowForSaleBadge = assetIsListed && !isPublic
  const badgeSize = isSmallLayout ? 'fluid-sm' : 'fluid-lg'

  const { content: productCardInfo } = ProductCardInfoRenderer({
    isSmallLayout: isSmallLayout,
    pillSlot: (
      <>
        {shouldShowForSaleBadge && (
          <MarketplaceBadges size={badgeSize} isInactive={!!+migrating} />
        )}
        <ExclusiveProductBadge
          isVisible={exclusive}
          isSmallLayout={isSmallLayout}
        />
      </>
    ),
    className: classNames(
      'flex flex-col flex-1 justify-center',
      productInfoClasses
    ),
    rarity: rarity,
    attributes: attributes,
    drop_name: drop_name,
    redeemable: redeemable,
    asset_name: name,
    footer: actionableButtonSlot
  })

  const isShowingPillAndButton = actionableButtonSlot // && isShowingPill

  const _mediaClasses = mediaClasses
    ? mediaClasses
    : isSmallLayout
    ? isShowingPillAndButton
      ? 'aspect-[200/135]'
      : 'aspect-[200/164]'
    : isShowingPillAndButton
    ? 'aspect-[338/252]'
    : 'aspect-[338/304]'

  const disabledReason = getFrozenAssetType(
    drop_marketplace_disabled,
    drop_marketplace_disabled_primary,
    drop_marketplace_disabled_snapshot,
    chain_status_text
  )

  return (
    <ProductCard
      {...props}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
      isSmallLayout={isSmallLayout}
      asset={asset}
      mediaClasses={_mediaClasses}
      className={className}
      infoSlot={productCardInfo}
      overlayChildren={
        isTransferable && (
          <InventoryItemSelector
            className="absolute z-[3]"
            style={{
              top: `${isSmallLayout ? '1.5em' : '3em'}`,
              right: `${isSmallLayout ? '1.25em' : '3em'}`
            }}
            disabledReason={disabledReason}
            activeSelector={isAnyItemSelectedForTransfer}
            selected={isItemSelectedForTransfer}
            isSmallLayout={isSmallLayout}
            isHovered={isHovered}
            onChange={() => onItemTransferSelectionChange()}
          />
        )
      }
    />
  )
}

export default InventoryItem

const getFrozenAssetType = (
  drop_marketplace_disabled?: boolean,
  drop_marketplace_disabled_primary?: boolean,
  drop_marketplace_disabled_snapshot?: boolean,
  chain_status_text?: string
): keyof typeof ASSET_DISABLED_REASON | undefined => {
  if (chain_status_text === 'locked') {
    return ASSET_DISABLED_REASON.locked
  }

  if (isTransferring(chain_status_text)) {
    return ASSET_DISABLED_REASON.transferring
  }

  return undefined

  // This work is not currently for MVP but will be implemented in the future - Josh Dobson (4/6/23)
  if (drop_marketplace_disabled) {
    return ASSET_DISABLED_REASON.generic
  }

  if (drop_marketplace_disabled_primary) {
    return ASSET_DISABLED_REASON.primarySale
  }

  if (drop_marketplace_disabled_snapshot) {
    return ASSET_DISABLED_REASON.snapshot
  }

  return undefined
}
